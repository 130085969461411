<template>
  <v-card tile class="mx-auto" :loading="tbl_ldg" :disabled="tbl_ldg">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: 'asegurados',
              params: { contractor_id: contractor_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${contractor.name ? `${contractor.name} | ` : ''}${
                $route.meta.title
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom v-if="code_send && code_verify">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="error"
                :href="
                  url_documents +
                  '/migrations/formato_migracion_asegurados.xlsx'
                "
                target="_blank"
              >
                <v-icon v-text="'mdi-download'" />
              </v-btn>
            </template>
            <span v-text="'Descargar formato'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense v-if="!code_send">
        <v-col cols="12" xs="12" md="4" offset-md="4">
          <p v-if="login.email2">
            Para continuar con esta operación se enviara un codigo de
            verificación al correo <b v-text="login.email2" />
          </p>
          <p v-else>
            Para continuar con esta operación su usuario debe de tener un correo
            electrónico registrado
          </p>
        </v-col>
        <v-col cols="12" xs="12" md="4" offset-md="4" v-if="login.email2">
          <v-btn
            block
            color="success"
            @click="codeSend"
            :disabled="code_loading"
            :loading="code_loading"
          >
            <v-icon left v-text="'mdi-arrow-right'" />
            Continuar
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense v-if="code_send && !code_verify">
        <v-col cols="12">
          <v-form v-on:submit.prevent ref="form_code_verify" lazy-validation>
            <v-row dense>
              <v-col cols="12" xs="12" md="4" offset-md="4">
                <p>
                  Para continuar con esta operación se enviara un codigo de
                  verificación al correo <b v-text="login.email2" />, favor de
                  introducirlo a continuación.
                </p>
              </v-col>
              <v-col cols="12" xs="12" md="4" offset-md="4">
                <v-text-field
                  label="Código"
                  v-model="code"
                  type="text"
                  dense
                  counter="8"
                  :rules="rules.required"
                  :disabled="code_loading"
                />
              </v-col>
              <v-col cols="12" xs="12" md="2" offset-md="4">
                <v-btn
                  block
                  @click="code_send = false"
                  :disabled="code_loading"
                  :loading="code_loading"
                >
                  <v-icon left v-text="'mdi-arrow-left'" />
                  Átras
                </v-btn>
              </v-col>
              <v-col cols="12" xs="12" md="2">
                <v-btn
                  block
                  color="success"
                  @click="codeVerify"
                  :disabled="code_loading"
                  :loading="code_loading"
                >
                  <v-icon left v-text="'mdi-lock'" />
                  Verificar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row dense v-if="code_send && code_verify">
      <!-- <v-row dense> -->
        <v-col cols="12">
          <v-switch
            :label="'Información de: ' + (data.get_db ? 'APP' : 'ARCHIVO')"
            v-model="data.get_db"
            class="mt-0"
            @change="infoSrcDef"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col v-if="data.get_db" cols="12" xs="12" md="3">
          <v-btn
            block
            :color="!data.insurance_migration ? 'warning' : 'info'"
            @click.prevent="insuranceMigrationsDlg"
            :disabled="tbl.length > 0"
            x-small
            class="mt-2"
          >
            <div v-if="!data.insurance_migration">
              Buscar
              <v-icon x-small right> mdi-magnify </v-icon>
            </div>
            <div v-else>
              {{ data.insurance_migration.created_at }}
              <v-icon x-small right> mdi-check </v-icon>
            </div>
          </v-btn>
        </v-col>
        <v-col v-else cols="12" xs="12" md="3">
          <v-file-input
            v-model="data.document_file_0"
            counter
            truncate-length="15"
            label="Formato XLSX"
            accept=".xlsx"
            show-size
            dense
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-autocomplete
            label="Aseguradora"
            v-model="data.insurance_id"
            :items="insurances"
            item-value="id"
            :item-text="(item) => item.name"
            :loading="insurances_loading"
            dense
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-radio-group
            v-model="data.birthday"
            @change="data.analyze_insured_type = false"
            class="mt-0"
            row
            :disabled="tbl.length > 0"
          >
            <v-radio label="Tipo asegurado" :value="false" />
            <v-radio label="Fecha nacimiento" :value="true" />
          </v-radio-group>
        </v-col>
        <v-col cols="12" xs="12" md="3" v-if="!file_load && !data.birthday">
          <v-switch
            :label="'Autoasignar'"
            v-model="data.analyze_insured_type"
            class="mt-0"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" v-if="tbl.length == 0">
          <v-btn
            block
            color="success"
            :disabled="
              (data.get_db && data.insurance_migration == null) ||
              (!data.get_db && data.document_file_0 == null) ||
              data.insurance_id == null
            "
            @click="getTbldata"
          >
            Analizar
            <v-icon right> mdi-upload </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" v-else>
          <v-btn block @click="tbl = []">
            Cargar otro formato
            <v-icon rigth> mdi-reload </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="tbl.length > 0">
          <v-card flat :loading="tbl_ldg" :disabled="tbl_ldg">
            <v-card-title>
              <span
                v-text="
                  `DETALLE ${
                    tbl_ldg
                      ? ''
                      : `(${tbl.length}) | ASEGURADORA (${
                          insurances.find((v) => v.id === data.insurance_id)
                            .name
                        })`
                  }`
                "
              />
              <v-spacer />
              <v-text-field
                v-model="tbl_srch"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :headers="tbl_hdrs"
              :items="tbl"
              :items-per-page="30"
              :search="tbl_srch"
              :loading="tbl_ldg"
              :item-class="itemRowBackground"
              item-key="index"
              :expanded.sync="expanded"
              show-expand
              dense
            >
              <template v-slot:item.index="{ item }">
                <span class="font-weight-bold" v-text="item.index" />
              </template>
              <template v-slot:item.name="{ item }">
                <span
                  :class="
                    item.insured && item.name == item.insured.name
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.name"
                />
              </template>
              <template v-slot:item.first_surname="{ item }">
                <span
                  :class="
                    item.insured &&
                    item.first_surname == item.insured.first_surname
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.first_surname"
                />
              </template>
              <template v-slot:item.second_surname="{ item }">
                <span
                  :class="
                    item.insured &&
                    item.second_surname == item.insured.second_surname
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.second_surname"
                />
              </template>
              <template v-slot:item.birthday="{ item }">
                <span
                  :class="
                    item.insured && item.birthday == item.insured.birthday
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.birthday"
                />
              </template>
              <template v-slot:item.insured_type_id="{ item }">
                <span
                  v-if="
                    !item.insured ||
                    (item.insured &&
                      item.temp_insured_type_id == item.insured.insured_type_id)
                  "
                  :class="
                    item.insured &&
                    item.insured_type_id == item.insured.insured_type_id
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.insured_type_id"
                />
                <div
                  v-if="
                    item.insured &&
                    item.temp_insured_type_id != item.insured.insured_type_id
                  "
                >
                  <v-autocomplete
                    label=""
                    v-model="item.insured_type_id"
                    :items="insured_types"
                    item-value="id"
                    :item-text="(v) => v.id"
                    :loading="insured_types_ldg"
                    dense
                  />
                </div>
              </template>
              <template v-slot:item.enrollment="{ item }">
                <span
                  :class="
                    item.insured && item.enrollment == item.insured.enrollment
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.enrollment"
                />
              </template>
              <template v-slot:item.policy="{ item }">
                <span
                  :class="
                    item.insured && item.policy == item.insured.policy
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.policy"
                />
              </template>
              <template v-slot:item.credential="{ item }">
                <span
                  :class="
                    item.insured && item.credential == item.insured.credential
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.credential"
                />
              </template>
              <template v-slot:item.office_address="{ item }">
                <span
                  :class="
                    item.insured &&
                    item.office_address == item.insured.office_address
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.office_address"
                />
              </template>
              <template v-slot:item.validity="{ item }">
                <span
                  :class="
                    item.insured && item.validity == item.insured.validity
                      ? ''
                      : 'blue--text'
                  "
                  v-text="item.validity"
                />
              </template>
              <template v-slot:item.insured.insurance.name="{ item }">
                <v-icon
                  small
                  :color="
                    item.insured &&
                    item.insured.insurance_id === data.insurance_id
                      ? 'green'
                      : 'blue'
                  "
                  v-text="
                    `mdi-${
                      item.insured &&
                      item.insured.insurance_id === data.insurance_id
                        ? 'check'
                        : 'circle-edit-outline'
                    }`
                  "
                />
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td v-if="item.insured" :colspan="headers.length" class="pt-2">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'Nombre'" />
                          <th v-text="'A. paterno'" />
                          <th v-text="'A. materno'" />
                          <th v-text="'F. nacimiento'" />
                          <th v-text="'Tipo'" />
                          <th v-text="'ID'" />
                          <th v-text="'Póliza'" />
                          <th v-text="'Núm. asegurado'" />
                          <th v-text="'Nom. oficina'" />
                          <th v-text="'Vigencia'" />
                          <th v-text="'Antigüedad'" />
                          <th v-text="'F. nacimiento'" />
                          <th v-text="'Aseguradora'" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td v-text="item.insured.name" />
                          <td v-text="item.insured.first_surname" />
                          <td v-text="item.insured.second_surname" />
                          <td v-text="item.insured.birthday" />
                          <td v-text="item.insured.insured_type_id" />
                          <td v-text="item.insured.enrollment" />
                          <td v-text="item.insured.policy" />
                          <td v-text="item.insured.credential" />
                          <td v-text="item.insured.office_address" />
                          <td v-text="item.insured.validity" />
                          <td v-text="item.insured.antiquity" />
                          <td v-text="item.insured.birthday" />
                          <td v-text="item.insured.insurance.name" />
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br />
                  <br />
                  <br />
                </td>
              </template>
            </v-data-table>
            <div class="text-right pt-5 pb-3">
              <v-btn
                v-if="login.permissions.insureds_migration.create"
                color="warning"
                @click.prevent="execMigration"
              >
                Guardar
                <v-icon right> mdi-check </v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="insurance_migrations_dlg" persistent max-width="900">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title> </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="insurance_migrations_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-col cols="12">
            <v-data-table
              :headers="insurance_migrations_hdrs"
              :search="tbl_srch"
              :items="insurance_migrations"
              :loading="insurance_migrations_dlg_ldg"
              dense
            >
              <template v-slot:item.index="{ item, index }">
                <b>{{ index + 1 }}</b>
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      x-small
                      outlined
                      color="success"
                      @click.prevent="
                        fileDownload(item.base64, item.ext, item.id)
                      "
                    >
                      <v-icon> mdi-microsoft-excel </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Descargar'" />
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      x-small
                      outlined
                      color="warning"
                      @click.prevent="insuranceMigrationsSelect(item)"
                      class="ml-1"
                    >
                      <v-icon> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Seleccionar'" />
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  URL_DOCUMENTS,
  rules,
  toFormData,
  dateTimeToFile,
  base64ToArrayBuffer,
} from "../../control";
import { index, show } from "../../requests/pageRequest";
import { catalog } from "../../requests/catalogRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      contractor_id: parseInt(this.$route.params.contractor_id),
      contractor: {},
      login: this.$store.getters.getLogin,
      rules: rules(),
      tbl: [],
      tbl_ldg: false,
      tbl_srch: "",
      tbl_hdrs: [
        {
          text: "#",
          value: "index",
        },
        {
          text: "Nombre",
          filterable: true,
          value: "name",
        },
        {
          text: "A. paterno",
          filterable: true,
          value: "first_surname",
        },
        {
          text: "A. materno",
          filterable: true,
          value: "second_surname",
        },
        {
          text: "F. nacimiento",
          filterable: true,
          value: "birthday",
        },
        {
          text: "Tipo",
          filterable: true,
          value: "insured_type_id",
          width: "100px",
        },
        {
          text: "ID SM",
          filterable: true,
          value: "enrollment",
        },
        {
          text: "Póliza",
          filterable: true,
          value: "policy",
        },
        {
          text: "Núm. asegurado",
          filterable: true,
          value: "credential",
        },
        {
          text: "Nom. oficina",
          filterable: true,
          value: "office_address",
        },
        {
          text: "Vigencia",
          filterable: true,
          value: "validity",
        },
        {
          text: "Antigüedad",
          filterable: true,
          value: "antiquity",
        },
        {
          text: "F. nacimiento",
          filterable: true,
          value: "birthday",
        },
        {
          text: "RFC",
          filterable: true,
          value: "code_tax",
        },
        {
          text: "Suma potenciada",
          filterable: true,
          value: "powered_amount",
        },
        {
          text: "Aseguradora",
          filterable: true,
          value: "insured.insurance.name",
        },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      insurances: [],
      insurances_loading: true,
      file_load: false,
      data: {},
      url_documents: URL_DOCUMENTS,
      code_send: false,
      code_verify: false,
      code: "",
      code_loading: false,
      insured_types: [],
      insured_types_ldg: true,
      insurance_migrations: [],
      insurance_migrations_dlg: false,
      insurance_migrations_dlg_ldg: false,
      insurance_migrations_hdrs: [
        {
          text: "#",
          filterable: false,
          value: "index",
        },
        {
          text: "Fecha",
          filterable: false,
          value: "created_at",
        },
        {
          text: "Usuario",
          filterable: false,
          value: "created_by.email",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "105px",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    codeSend() {
      this.code_loading = true;
      this.code = "";
      this.code_send = false;
      this.code_verify = false;

      Axios.post(
        URL_API + `/verify/codes`,
        {
          id: null,
        },
        headersToken(this.login.token)
      ).then((response) => {
        if (response.data.success) {
          this.code_send = true;
        } else {
          this.$swal.fire(msgAlert("error", response.data.message));
        }

        this.code_loading = false;
      });
    },
    codeVerify() {
      if (this.$refs.form_code_verify.validate()) {
        this.code_loading = true;
        this.code_send = true;
        this.code_verify = false;

        Axios.post(
          URL_API + `/verify/codes/use`,
          {
            code: this.code,
          },
          headersToken(this.login.token)
        ).then((response) => {
          if (response.data.success) {
            this.code_verify = true;
          } else {
            this.$swal.fire(msgAlert("error", response.data.message));
          }

          this.code_loading = false;
        });
      }
    },
    getTbldata() {
      this.tbl_ldg = true;

      Axios.post(
        URL_API + "/insureds/migration/details",
        toFormData(this.data),
        headersToken(this.login.token)
      )
        .then((res) => {
          this.tbl = res.data.data.list_insureds;
          this.tbl_ldg = false;
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire(msgAlert("error", "Formato del documento invalido"));
          this.tbl_ldg = false;
        });
    },
    itemRowBackground: function (item) {
      return item.to_save
        ? item.insured
          ? ""
          : "red lighten-5"
        : "orange lighten-5";
    },
    execMigration() {
      this.$swal
        .fire(msgConfirm("¿Confirma ejecutar la migración?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.tbl_ldg = true;

            Axios.post(
              URL_API + "/insureds/migration",
              {
                list_insureds: this.tbl,
                contractor_id: this.contractor_id,
                insurance_id: this.data.insurance_id,
                insurance_migration_id: this.data.insurance_migration_id,
              },
              headersToken(this.login.token)
            )
              .then((response) => {
                response = response.data;

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );
                if (response.success) {
                  this.$router.push({
                    name: "asegurados",
                    params: { contractor_id: this.contractor_id },
                  });
                } else {
                  console.log(response.message);
                }
              })
              .catch((error) => {
                console.log(error);
                this.$swal.fire(
                  msgAlert(
                    "error",
                    "Error en archivo de migración. Revise cuidadosamente el documento."
                  )
                );
              });
          }
        });
    },
    infoSrcDef() {
      this.data.insurance_migration = null;
      this.data.insurance_migration_id = null;
      this.data.document_file_0 = null;
    },
    insuranceMigrationsDlg() {
      this.insurance_migrations = [];
      this.insurance_migrations_dlg_ldg = true;
      this.insurance_migrations_dlg = true;

      Axios.get(
        URL_API + "/insurance/migrations/contractor/" + this.data.contractor_id,
        headersToken(this.login.token)
      ).then((res) => {
        this.insurance_migrations = res.data.data;
        this.insurance_migrations_dlg_ldg = false;
      });
    },
    insuranceMigrationsSelect(item) {
      this.data.insurance_migration = item;
      this.data.insurance_migration_id = item.id;
      this.insurance_migrations_dlg = false;
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    show("contractors", this.login.token, this.contractor_id).then((res) => {
      this.contractor = res;
    });

    catalog("insured_types", this.login.token).then((res) => {
      this.insured_types = res;
      this.insured_types_ldg = false;
    });

    index("insurances", this.login.token, []).then((res) => {
      this.insurances = res.data;
      this.insurances_loading = false;
    });

    this.data = {
      contractor_id: this.contractor_id,
      get_db: true,
      insurance_migration: null,
      insurance_migration_id: null,
      document_file_0: null,
      insurance_id: null,
      birthday: false,
      analyze_insured_type: true,
    };
  },
};
</script>